import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import {
  AssessmentNominationFragment,
  AssessmentType,
  GetComplianceProgramActionsQuery,
  GetComplianceProgramActionsQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import getComplianceProgramActionsQuery from "../graphql/get-compliance-program-actions-query";
import {
  ComplianceProgramActionBasicItemProps,
  getAssessmentItemsForTemplate,
  getOneononeItemsFromComplianceProgram,
  mapAssessmentDeliveryToItem,
  mapAssessmentForNominationToItem,
} from "../helpers";
import { ProgramActionToDoStateFilter } from "./compliance-program";
import ComplianceProgramActionItem from "./compliance-program-action-item";
import ComplianceProgramActionItemFor1on1 from "./compliance-program-action-item-for-1-on-1";
import ComplianceProgramActionItemForAssessment from "./compliance-program-action-item-for-assessment";
import ComplianceProgramActionItemForNomination from "./compliance-program-action-item-for-nomination";

const ComplianceProgramMyActions = ({
  complianceProgramId,
  actionToDoStateFilter,
  onChangeActionToDoStateFilter,
}: {
  complianceProgramId: number;
  actionToDoStateFilter: ProgramActionToDoStateFilter;
  onChangeActionToDoStateFilter: (filter: ProgramActionToDoStateFilter) => void;
}) => {
  const currentUser = currentUserVar();
  const reportIds = useMemo(
    () =>
      assertEdgesNonNull(currentUser.directReports).map((report) => report.id),
    [currentUser.directReports]
  );
  const label = useLabel();

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<
      GetComplianceProgramActionsQuery,
      GetComplianceProgramActionsQueryVariables
    >(getComplianceProgramActionsQuery, {
      variables: {
        complianceProgramId,
        responderId: currentUser.id,
        deliveryTargetIds: [...reportIds, currentUser.id],
      },
      onError: onNotificationErrorHandler(),
    });

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );

  const deliveries = complianceProgramData?.assessmentDeliveries
    ? assertEdgesNonNull(complianceProgramData.assessmentDeliveries)
    : [];

  const assessmentOpenForNominations = useMemo(
    () =>
      compact(
        complianceProgramData?.assessmentsOpenForNominations
          ? complianceProgramData.assessmentsOpenForNominations.edges.map(
              (edge) => edge!.node
            )
          : []
      ),
    [complianceProgramData]
  );

  const filterDoneState = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      actionToDoStateFilter === ProgramActionToDoStateFilter.All ||
      (actionToDoStateFilter === ProgramActionToDoStateFilter.ToDo &&
        !item.statusCompleted) ||
      (actionToDoStateFilter === ProgramActionToDoStateFilter.Completed &&
        !!item.statusCompleted),
    [actionToDoStateFilter]
  );

  const filterForCurrentUser = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      item.user?.id === currentUser.id && filterDoneState(item),
    [currentUser.id, filterDoneState]
  );
  const filterForDirectReports = useCallback(
    (item: ComplianceProgramActionBasicItemProps) => {
      return (
        item.user?.id &&
        reportIds.includes(item.user.id) &&
        filterDoneState(item)
      );
    },
    [reportIds, filterDoneState]
  );
  const filterForOtherUsers = useCallback(
    (item: ComplianceProgramActionBasicItemProps) => {
      return (
        item.user?.id &&
        item.user.id !== currentUser.id &&
        !reportIds.includes(item.user.id) &&
        filterDoneState(item)
      );
    },
    [currentUser.id, filterDoneState, reportIds]
  );

  if (isLoadingComplianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading program..</Loading>
      </div>
    );
  }

  if (!complianceProgram) {
    return (
      <div className="flex-1 flex justify-center p-10">Program not found</div>
    );
  }

  const performanceAssessmentItems =
    complianceProgram?.performanceAssessmentTemplate
      ? getAssessmentItemsForTemplate(
          complianceProgram,
          complianceProgram.performanceAssessmentTemplate,
          currentUser.id
        )
      : [];
  const otherPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForOtherUsers);
  const myPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForCurrentUser);
  const directReportsPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForDirectReports);
  const managerAssessmentItems = complianceProgram?.managerAssessmentTemplate
    ? getAssessmentItemsForTemplate(
        complianceProgram,
        complianceProgram.managerAssessmentTemplate,
        currentUser.id
      )
    : [];
  const otherManagerAssessmentItems =
    managerAssessmentItems.filter(filterForOtherUsers);
  const myManagerAssessmentItems =
    managerAssessmentItems.filter(filterForCurrentUser);
  const directReportsManagerAssessmentItems = managerAssessmentItems.filter(
    filterForDirectReports
  );

  const peerAssessmentItems = complianceProgram?.peerAssessmentTemplate
    ? getAssessmentItemsForTemplate(
        complianceProgram,
        complianceProgram.peerAssessmentTemplate,
        currentUser.id
      )
    : [];
  const myPeerAssessmentItems =
    peerAssessmentItems.filter(filterForCurrentUser);
  const otherPeerAssessmentItems =
    peerAssessmentItems.filter(filterForOtherUsers);
  const directReportsPeerAssessmentItems = peerAssessmentItems.filter(
    filterForDirectReports
  );

  const perfDeliveries = deliveries.filter(
    (delivery) =>
      delivery.template.assessmentType === AssessmentType.Performance
  );
  const perfDeliveryItems: ComplianceProgramActionBasicItemProps[] =
    perfDeliveries.map((delivery) =>
      mapAssessmentDeliveryToItem(currentUser.id, delivery)
    );
  const myPerfDeliveryItems = perfDeliveryItems.filter(filterForCurrentUser);
  const otherPerfDeliveryItems = perfDeliveryItems.filter(filterForOtherUsers);
  const directReportsPerfDeliveryItems = perfDeliveryItems.filter(
    filterForDirectReports
  );
  const peerDeliveries = deliveries.filter(
    (delivery) =>
      delivery.template.assessmentType === AssessmentType.Performance
  );
  const peerDeliveryItems: ComplianceProgramActionBasicItemProps[] =
    peerDeliveries.map((delivery) =>
      mapAssessmentDeliveryToItem(currentUser.id, delivery)
    );
  const myPeerDeliveryItems = peerDeliveryItems.filter(filterForCurrentUser);
  const otherPeerDeliveryItems = peerDeliveryItems.filter(filterForOtherUsers);
  const directReportsPeerDeliveryItems = peerDeliveryItems.filter(
    filterForDirectReports
  );
  const managerDeliveries = deliveries.filter(
    (delivery) =>
      delivery.template.assessmentType === AssessmentType.Performance
  );
  const managerDeliveryItems: ComplianceProgramActionBasicItemProps[] =
    managerDeliveries.map((delivery) =>
      mapAssessmentDeliveryToItem(currentUser.id, delivery)
    );
  const myManagerDeliveryItems =
    managerDeliveryItems.filter(filterForCurrentUser);
  const otherManagerDeliveryItems =
    managerDeliveryItems.filter(filterForOtherUsers);
  const directReportsManagerDeliveryItems = managerDeliveryItems.filter(
    filterForDirectReports
  );

  const nominations: (ComplianceProgramActionBasicItemProps & {
    assessmentOpenForNomination: AssessmentNominationFragment;
  })[] = assessmentOpenForNominations.map((assessmentOpenForNomination) =>
    mapAssessmentForNominationToItem(
      currentUser.id,
      assessmentOpenForNomination
    )
  );
  const otherNominations = nominations.filter(filterForOtherUsers);
  const myNominations = nominations.filter(filterForCurrentUser);
  const directReportsNominations = nominations.filter(filterForDirectReports);

  const oneononeItems = getOneononeItemsFromComplianceProgram(
    complianceProgram,
    currentUser.id
  );
  const otherOneononeItems = oneononeItems.filter(filterForOtherUsers);
  const myOneononeItems = oneononeItems.filter(filterForCurrentUser);
  const directReportsOneononeItems = oneononeItems.filter(
    filterForDirectReports
  );

  const myItemsCount =
    myPerformanceAssessmentItems.length +
    myManagerAssessmentItems.length +
    myPeerAssessmentItems.length +
    myOneononeItems.length +
    myPerfDeliveryItems.length +
    myPeerDeliveryItems.length +
    myManagerDeliveryItems.length +
    myNominations.length;
  const directReportsItemsCount =
    directReportsPerformanceAssessmentItems.length +
    directReportsManagerAssessmentItems.length +
    directReportsPeerAssessmentItems.length +
    directReportsOneononeItems.length +
    directReportsPerfDeliveryItems.length +
    directReportsPeerDeliveryItems.length +
    directReportsManagerDeliveryItems.length +
    directReportsNominations.length;
  const otherItemsCount =
    otherPerformanceAssessmentItems.length +
    otherManagerAssessmentItems.length +
    otherPeerAssessmentItems.length +
    otherOneononeItems.length +
    otherPerfDeliveryItems.length +
    otherPeerDeliveryItems.length +
    otherManagerDeliveryItems.length +
    otherNominations.length;

  return (
    <div
      className="flex flex-col max-w-screen-lg w-full mx-auto"
      aria-label="Compliance Program my actions"
    >
      <div className="flex px-8 py-4">
        <ToggleButtonGroup<ProgramActionToDoStateFilter>
          theme={ToggleButtonGroupTheme.buttons}
          buttons={[
            {
              title: ProgramActionToDoStateFilter.All,
              value: ProgramActionToDoStateFilter.All,
              active:
                actionToDoStateFilter === ProgramActionToDoStateFilter.All,
            },
            {
              title: ProgramActionToDoStateFilter.ToDo,
              value: ProgramActionToDoStateFilter.ToDo,
              active:
                actionToDoStateFilter === ProgramActionToDoStateFilter.ToDo,
            },
            {
              title: ProgramActionToDoStateFilter.Completed,
              value: ProgramActionToDoStateFilter.Completed,
              active:
                actionToDoStateFilter ===
                ProgramActionToDoStateFilter.Completed,
            },
          ]}
          onClick={(button) =>
            onChangeActionToDoStateFilter(
              button?.value || ProgramActionToDoStateFilter.All
            )
          }
        />
      </div>

      <Layout.MainSection title={`My ${label("review", { capitalize: true })}`}>
        {myItemsCount === 0 ? (
          <Layout.MainSubSectionEmpty>No actions.</Layout.MainSubSectionEmpty>
        ) : (
          <Layout.MainSubSectionList>
            {myPerformanceAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              />
            ))}
            {myManagerAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              />
            ))}
            {myPeerAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              />
            ))}
            {myOneononeItems.map((item) => (
              <ComplianceProgramActionItemFor1on1 key={item.id} item={item} />
            ))}
            {myNominations.map((item) => (
              <ComplianceProgramActionItemForNomination
                key={item.key}
                item={item}
              />
            ))}
            {myPerfDeliveryItems.map(({ key, ...item }) => (
              <ComplianceProgramActionItem key={key} {...item} />
            ))}
            {myPeerDeliveryItems.map(({ key, ...item }) => (
              <ComplianceProgramActionItem key={key} {...item} />
            ))}
            {myManagerDeliveryItems.map(({ key, ...item }) => (
              <ComplianceProgramActionItem key={key} {...item} />
            ))}
          </Layout.MainSubSectionList>
        )}
      </Layout.MainSection>

      {reportIds.length > 0 && (
        <Layout.MainSection title="Actions for Direct Reports">
          {directReportsItemsCount === 0 && (
            <Layout.MainSubSectionEmpty>No actions.</Layout.MainSubSectionEmpty>
          )}
          {directReportsPerformanceAssessmentItems.length > 0 && (
            <Layout.MainSubSection
              className="max-w-screen-lg"
              title={`${getAssessmentTypeLabel(
                AssessmentType.Performance
              )} ${label("review", {
                capitalize: true,
                pluralize: true,
              })} for Direct Reports`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsPerformanceAssessmentItems.map((item) => (
                  <ComplianceProgramActionItemForAssessment
                    key={item.key}
                    item={item}
                  />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}
          {directReportsManagerAssessmentItems.length > 0 && (
            <Layout.MainSubSection
              title={`${getAssessmentTypeLabel(AssessmentType.Manager)} ${label(
                "review",
                {
                  capitalize: true,
                  pluralize: true,
                }
              )}`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsManagerAssessmentItems.map((item) => (
                  <ComplianceProgramActionItemForAssessment
                    key={item.id}
                    item={item}
                  />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsPeerAssessmentItems.length > 0 && (
            <Layout.MainSubSection
              title={`${getAssessmentTypeLabel(AssessmentType.Peer)} ${label(
                "review",
                {
                  capitalize: true,
                  pluralize: true,
                }
              )}`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsPeerAssessmentItems.map((item) => (
                  <ComplianceProgramActionItemForAssessment
                    key={item.id}
                    item={item}
                  />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsOneononeItems.length > 0 && (
            <Layout.MainSubSection
              title={`${label("1-on-1", { pluralize: true })} Meetings`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsOneononeItems.map((item) => (
                  <ComplianceProgramActionItemFor1on1
                    key={item.key}
                    item={item}
                  />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsNominations.length > 0 && (
            <Layout.MainSubSection
              title="Nominations"
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsNominations.map((item) => (
                  <ComplianceProgramActionItemForNomination
                    key={item.key}
                    item={item}
                  />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsPerfDeliveryItems.length > 0 && (
            <Layout.MainSubSection
              title={`${getAssessmentTypeLabel(
                AssessmentType.Performance
              )} ${label("review", {
                capitalize: true,
                pluralize: true,
              })} to Deliver`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsPerfDeliveryItems.map(({ key, ...item }) => (
                  <ComplianceProgramActionItem key={key} {...item} />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsManagerDeliveryItems.length > 0 && (
            <Layout.MainSubSection
              title={`${getAssessmentTypeLabel(AssessmentType.Manager)} ${label(
                "review",
                {
                  capitalize: true,
                  pluralize: true,
                }
              )} to Deliver`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsManagerDeliveryItems.map(({ key, ...item }) => (
                  <ComplianceProgramActionItem key={key} {...item} />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}

          {directReportsPeerDeliveryItems.length > 0 && (
            <Layout.MainSubSection
              title={`${getAssessmentTypeLabel(AssessmentType.Peer)} ${label(
                "review",
                {
                  capitalize: true,
                  pluralize: true,
                }
              )} to Deliver`}
              collapsible
              defaultIsExpanded
            >
              <Layout.MainSubSectionList>
                {directReportsPeerDeliveryItems.map(({ key, ...item }) => (
                  <ComplianceProgramActionItem key={key} {...item} />
                ))}
              </Layout.MainSubSectionList>
            </Layout.MainSubSection>
          )}
        </Layout.MainSection>
      )}

      <Layout.MainSection title="Actions for Others">
        {otherItemsCount === 0 && (
          <Layout.MainSubSectionEmpty>No actions.</Layout.MainSubSectionEmpty>
        )}

        {otherPerformanceAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            className="max-w-screen-lg"
            title={`${getAssessmentTypeLabel(
              AssessmentType.Performance
            )} ${label("review", {
              capitalize: true,
              pluralize: true,
            })} for Others`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPerformanceAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {otherManagerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={`${getAssessmentTypeLabel(AssessmentType.Manager)} ${label(
              "review",
              {
                capitalize: true,
                pluralize: true,
              }
            )}`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherManagerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.id}
                  item={item}
                />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherPeerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={`${getAssessmentTypeLabel(AssessmentType.Peer)} ${label(
              "review",
              {
                capitalize: true,
                pluralize: true,
              }
            )}`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPeerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.id}
                  item={item}
                />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherOneononeItems.length > 0 && (
          <Layout.MainSubSection
            title={`${label("1-on-1", { pluralize: true })} Meetings`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherOneononeItems.map((item) => (
                <ComplianceProgramActionItemFor1on1
                  key={item.key}
                  item={item}
                />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherNominations.length > 0 && (
          <Layout.MainSubSection
            title="Nominations"
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherNominations.map((item) => (
                <ComplianceProgramActionItemForNomination
                  key={item.key}
                  item={item}
                />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherPerfDeliveryItems.length > 0 && (
          <Layout.MainSubSection
            title={`${getAssessmentTypeLabel(
              AssessmentType.Performance
            )} ${label("review", {
              capitalize: true,
              pluralize: true,
            })} to Deliver`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPerfDeliveryItems.map(({ key, ...item }) => (
                <ComplianceProgramActionItem key={key} {...item} />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherManagerDeliveryItems.length > 0 && (
          <Layout.MainSubSection
            title={`${getAssessmentTypeLabel(AssessmentType.Manager)} ${label(
              "review",
              {
                capitalize: true,
                pluralize: true,
              }
            )} to Deliver`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherManagerDeliveryItems.map(({ key, ...item }) => (
                <ComplianceProgramActionItem key={key} {...item} />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}

        {otherPeerDeliveryItems.length > 0 && (
          <Layout.MainSubSection
            title={`${getAssessmentTypeLabel(AssessmentType.Peer)} ${label(
              "review",
              {
                capitalize: true,
                pluralize: true,
              }
            )} to Deliver`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPeerDeliveryItems.map(({ key, ...item }) => (
                <ComplianceProgramActionItem key={key} {...item} />
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
      </Layout.MainSection>
    </div>
  );
};

export default ComplianceProgramMyActions;
