import { compact, range } from "lodash";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import {
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { CareerTrackRolePickerRole } from "@apps/org-settings/components/competencies/career-track-role-picker";
import {
  matchesReportsToFilter,
  matchesRoleFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import Button from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { PerformanceAssessmentAnswerFilter } from "../assessment-report";
import { TeamPickerTeam } from "../team-picker";

type NineBoxAssessmentAnswer = {
  assessmentId: number;
  integerAnswer: number;
  questionId: number;
};

const NineBoxGrid = ({
  children,
  rows,
  cols,
}: PropsWithChildren<{ rows: number; cols: number }>) => (
  <div
    className={classNames(
      "m-auto",
      "max-w-[900px]",
      "grid",
      "gap-4",
      `grid-rows-${rows}`,
      `grid-cols-${cols}`
    )}
  >
    {children}
  </div>
);

const NineBoxCell = ({
  count,
  totalAssessments,
  isFiltered,
  onClick,
}: {
  count: number;
  totalAssessments: number;
  isFiltered: boolean;
  onClick: () => void;
}) => (
  <div
    aria-label="Assessment report nine box cell"
    className={classNames(
      "relative flex flex-col items-center justify-center rounded-lg p-6 active:bg-indigo-300",
      !isFiltered && "bg-indigo-100",
      isFiltered && "bg-indigo-400",
      count === 0 && "bg-gray-200",
      count > 0 && "hover:cursor-pointer hover:bg-indigo-200"
    )}
    role="button"
    onClick={() => count > 0 && onClick()}
  >
    {isFiltered && <MdFilterAlt className="h-4 w-4 absolute top-2 left-2" />}
    <div className="font-bold text-lg">{count}</div>
    <div className="text-sm">
      ({Math.floor((count / totalAssessments) * 100)}
      %)
    </div>
  </div>
);

const PerformanceAssessmentChart = ({
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  answerFilterList,
  teamFilterList,
  roleFilterList,
  onChangeAnswerFilterList,
  questions,
}: {
  complianceProgram: GetComplianceProgramForReportQuery["complianceProgram"];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
  roleFilterList: CareerTrackRolePickerRole[];
  answerFilterList: PerformanceAssessmentAnswerFilter[];
  onChangeAnswerFilterList: (
    answerFilterList: PerformanceAssessmentAnswerFilter[]
  ) => void;
  questions: Pick<
    RangeAssessmentQuestionNode,
    "id" | "title" | "startValue" | "endValue" | "labels"
  >[];
}) => {
  const [selectedXQuestionIndex, setSelectedXQuestonIndex] = useState(0);
  const [selectedYQuestionIndex, setSelectedYQuestonIndex] = useState<
    number | null
  >(1);

  const handleChangeXQuestion = useCallback(
    (opt: ComboboxGenericOption<number>) => {
      setSelectedXQuestonIndex(opt.value);
      onChangeAnswerFilterList([]);
    },
    [onChangeAnswerFilterList]
  );
  const handleChangeYQuestion = useCallback(
    (opt: ComboboxGenericOption<number>) => {
      setSelectedYQuestonIndex(opt.value);
      onChangeAnswerFilterList([]);
    },
    [onChangeAnswerFilterList]
  );
  const handleClearYQuestion = useCallback(() => {
    setSelectedYQuestonIndex(null);
    onChangeAnswerFilterList([]);
  }, [onChangeAnswerFilterList]);

  const handleUpdateAnswerFilter = useCallback(
    (
      xFilter: PerformanceAssessmentAnswerFilter,
      yFilter?: PerformanceAssessmentAnswerFilter
    ) => {
      onChangeAnswerFilterList(compact([xFilter, yFilter]));
    },
    [onChangeAnswerFilterList]
  );

  useEffect(() => {
    setSelectedXQuestonIndex(0);
    setSelectedYQuestonIndex(1);
  }, [complianceProgram]);

  const xQuestion = useMemo(
    () => (questions.length > 0 ? questions[selectedXQuestionIndex] : null),
    [questions, selectedXQuestionIndex]
  );
  const yQuestion = useMemo(
    () =>
      questions.length > 1 && selectedYQuestionIndex !== null
        ? questions[selectedYQuestionIndex]
        : null,
    [questions, selectedYQuestionIndex]
  );

  const assessments = useMemo(
    () =>
      complianceProgram
        ? assertEdgesNonNull(complianceProgram.assessments).filter(
            // filter self assessments from chart
            (assessment) => assessment.responder?.id !== assessment.target?.id
          )
        : [],
    [complianceProgram]
  );

  const filterAssessment = useCallback(
    (assessment: typeof assessments[0]) => {
      const target = assertNonNull(assessment.target);
      return (
        matchesUserFilter(userFilterList, target) &&
        matchesReportsToFilter(reportsToFilterList, target) &&
        matchesTeamsFilter(teamFilterList, target) &&
        matchesRoleFilter(roleFilterList, assessment)
      );
    },
    [reportsToFilterList, userFilterList, teamFilterList, roleFilterList]
  );

  const xQuestionAnswers = useMemo(
    () =>
      xQuestion && assessments.length > 0
        ? assessments.filter(filterAssessment).reduce((ansList, assessment) => {
            const answers = assertEdgesNonNull(assessment.answers);
            return ansList.concat(
              compact(
                answers
                  .filter((a) => a.question.id === xQuestion.id)
                  .map((a) =>
                    a.__typename === "RangeAssessmentAnswerNode"
                      ? {
                          assessmentId: assessment.id,
                          questionId: a.question.id,
                          integerAnswer: assertNonNull(a.integerAnswer),
                        }
                      : null
                  )
              )
            );
          }, [] as NineBoxAssessmentAnswer[])
        : [],
    [xQuestion, assessments, filterAssessment]
  );
  const yQuestionAnswers = useMemo(
    () =>
      yQuestion && assessments.length > 0
        ? assessments.filter(filterAssessment).reduce((ansList, assessment) => {
            const answers = assertEdgesNonNull(assessment.answers);
            return ansList.concat(
              compact(
                answers
                  .filter((a) => a.question.id === yQuestion.id)
                  .map((a) =>
                    a.__typename === "RangeAssessmentAnswerNode"
                      ? {
                          assessmentId: assessment.id,
                          questionId: a.question.id,
                          integerAnswer: assertNonNull(a.integerAnswer),
                        }
                      : null
                  )
              )
            );
          }, [] as NineBoxAssessmentAnswer[])
        : [],
    [yQuestion, assessments, filterAssessment]
  );

  if (questions.length === 0 || assessments.length === 0) {
    return null;
  }

  return (
    <div className="p-4 rounded-lg mb-4 bg-gray-100">
      {xQuestion && yQuestion && (
        <NineBoxGrid
          rows={yQuestion.endValue - yQuestion.startValue + 2}
          cols={xQuestion.endValue - xQuestion.startValue + 2}
        >
          {range(yQuestion.startValue, yQuestion.endValue + 1)
            .reverse()
            .map((yValue, index) => (
              <Fragment key={yValue}>
                <div className="flex items-center justify-center">
                  {yQuestion.labels.toReversed()[index] || yValue}
                </div>
                {range(xQuestion.startValue, xQuestion.endValue + 1).map(
                  (xValue) => {
                    const xAnswers = xQuestionAnswers.filter(
                      (answer) => answer.integerAnswer === xValue
                    );
                    const yAnswerIds = yQuestionAnswers
                      .filter((answer) => answer.integerAnswer === yValue)
                      .map((answer) => answer.assessmentId);
                    const matchingAnswerCount = xAnswers.filter((answer) =>
                      yAnswerIds.includes(answer.assessmentId)
                    ).length;

                    return (
                      <NineBoxCell
                        key={xValue}
                        isFiltered={
                          !!answerFilterList.find(
                            (answerFilter) =>
                              answerFilter.questionId === xQuestion.id &&
                              answerFilter.integerAnswer === xValue
                          ) &&
                          !!answerFilterList.find(
                            (answerFilter) =>
                              answerFilter.questionId === yQuestion.id &&
                              answerFilter.integerAnswer === yValue
                          )
                        }
                        count={matchingAnswerCount}
                        totalAssessments={assessments.length}
                        onClick={() =>
                          handleUpdateAnswerFilter(
                            { questionId: xQuestion.id, integerAnswer: xValue },
                            { questionId: yQuestion.id, integerAnswer: yValue }
                          )
                        }
                      />
                    );
                  }
                )}
              </Fragment>
            ))}
          <div className="h-12"></div>
          {range(xQuestion.startValue, xQuestion.endValue + 1).map(
            (value, index) => (
              <div
                key={value}
                className="flex items-center justify-center text-center h-12"
              >
                {xQuestion.labels[index] || value}
              </div>
            )
          )}
        </NineBoxGrid>
      )}
      {xQuestion && !yQuestion && (
        <NineBoxGrid
          rows={2}
          cols={xQuestion.endValue - xQuestion.startValue + 1}
        >
          {range(xQuestion.startValue, xQuestion.endValue + 1).map((xValue) => {
            const matchingAnswerCount = xQuestionAnswers.filter(
              (answer) => answer.integerAnswer === xValue
            ).length;

            return (
              <NineBoxCell
                key={xValue}
                isFiltered={
                  !!answerFilterList.find(
                    (answerFilter) =>
                      answerFilter.questionId === xQuestion.id &&
                      answerFilter.integerAnswer === xValue
                  )
                }
                count={matchingAnswerCount}
                totalAssessments={assessments.length}
                onClick={() =>
                  handleUpdateAnswerFilter({
                    questionId: xQuestion.id,
                    integerAnswer: xValue,
                  })
                }
              />
            );
          })}
          {range(xQuestion.startValue, xQuestion.endValue + 1).map(
            (value, index) => (
              <div
                key={value}
                className="flex items-center justify-center text-center h-12"
              >
                {xQuestion.labels[index] || value}
              </div>
            )
          )}
        </NineBoxGrid>
      )}
      {questions.length > 1 && (
        <div className="flex items-center justify-center w-full gap-4 flex-wrap">
          <div className="flex items-center gap-2">
            <div className="font-bold">Y:</div>
            <ComboboxGeneric
              className="min-w-32 max-w-64 sm:max-w-sm"
              clearable
              options={questions.map((q, i) => ({
                label: q.title,
                value: i,
              }))}
              onClearValue={handleClearYQuestion}
              onChangeValue={handleChangeYQuestion}
              value={
                selectedYQuestionIndex !== null &&
                questions[selectedYQuestionIndex]
                  ? {
                      label: questions[selectedYQuestionIndex].title,
                      value: selectedYQuestionIndex,
                    }
                  : null
              }
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="font-bold">X:</div>
            <ComboboxGeneric
              className="min-w-32 max-w-64 sm:max-w-sm"
              options={questions.map((q, i) => ({
                label: q.title,
                value: i,
              }))}
              onChangeValue={handleChangeXQuestion}
              value={
                questions[selectedXQuestionIndex]
                  ? {
                      label: questions[selectedXQuestionIndex].title,
                      value: selectedXQuestionIndex,
                    }
                  : null
              }
            />
          </div>
          <Button
            className="flex items-center w-16"
            disabled={answerFilterList.length === 0}
            onClick={() => onChangeAnswerFilterList([])}
          >
            <MdFilterAltOff className="h-5 w-5 mr-1" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default PerformanceAssessmentChart;
