import { useMutation } from "@apollo/client";
import { compact, flatMap, isInteger } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentQuestionResponses,
  AssessmentQuestionType,
  AssessmentState,
  DeleteAssessmentAnswerMutationMutation,
  DeleteAssessmentAnswerMutationMutationVariables,
  GetAssessmentQuery,
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  errorNotificationVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import createOrUpdateAssessmentMutation from "../../graphql/create-or-update-assessment-mutation";
import deleteAssessmentAnswerMutation from "../../graphql/delete-assessment-answer-mutation";
import getMyAssessmentsQuery from "../../graphql/get-my-assessments-query";
import getPreviousAssessmentsQuery from "../../graphql/get-previous-assessments-query";
import { bgClassName } from "../../helpers";
import AssessmentQuestionItem from "../questions/assessment-question-item";
import GoalBasedQuestions from "../questions/goal-based-questions";
import {
  isGoalBasedQuestionType,
  isRoleBasedQuestionType,
} from "../questions/helpers";
import { AssessmentAnswer, AssessmentSection } from "../questions/types";
import AssessmentHeader from "./assessment-header";
import AssessmentRoleBasedSection from "./assessment-role-based-section";

const AssessmentRightCol = ({
  myAssessmentsData,
  isLoadingAssessment,
  proposedAnswers,
  onChangeProposedAnswers,
}: {
  proposedAnswers: AssessmentAnswer[];
  onChangeProposedAnswers: (answers: AssessmentAnswer[]) => void;
  myAssessmentsData?: GetAssessmentQuery;
  isLoadingAssessment: boolean;
}) => {
  const debouncedProposedAnswers = useDebounce(proposedAnswers, 200);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();
  const location = useLocation<TFLocationState>();
  const backUrl = location.state?.previousPathname || "/assessments";
  const [showErrors, setShowErrors] = useState(false);

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const assessmentTemplateId = useMemo(
    () => (assessment ? assessment.template.id : null),
    [assessment]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );
  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const sectionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    const additionQuestionSets = assertEdgesNonNull(
      assessment.additionalQuestionSets
    );
    const addtionalSections = flatMap(additionQuestionSets, (set) => {
      const newSections: AssessmentSection[] = assertEdgesNonNull(
        set.sections
      ).map((section) => {
        const questions = assertEdgesNonNull(section.questions);
        return {
          id: section.id,
          title: section.title,
          description: section.description,
          questions,
        };
      });
      return newSections;
    });
    const questionSets = assertEdgesNonNull(assessment.template.questionSets);
    const regularSections = flatMap(questionSets, (set) => {
      const newSections: AssessmentSection[] = assertEdgesNonNull(
        set.sections
      ).map((section) => {
        const questions = assertEdgesNonNull(section.questions);
        return {
          id: section.id,
          title: section.title,
          description: section.description,
          questions,
        };
      });
      return newSections;
    });
    return [...addtionalSections, ...regularSections];
  }, [assessment]);

  const questionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    const allQuestions = flatMap(sectionNodes, (section) => section.questions);
    return allQuestions.filter((node) => {
      const { question } = node;
      return (
        question.responses === AssessmentQuestionResponses.Both ||
        (question.responses ===
          AssessmentQuestionResponses.ExcludeSelfAssessment &&
          !isSelfAssessment) ||
        (question.responses ===
          AssessmentQuestionResponses.SelfAssessmentOnly &&
          isSelfAssessment)
      );
    });
  }, [assessment, isSelfAssessment, sectionNodes]);

  const totalQuestionWeight = useMemo(
    () => questionNodes.reduce((sum, node) => sum + node.weight, 0),
    [questionNodes]
  );

  const totalWeightedScore = useMemo(() => {
    return questionNodes
      .filter(
        (questionNode) =>
          questionNode.question.questionType === AssessmentQuestionType.Range
      )
      .reduce((sum, node) => {
        const answer = proposedAnswers.find(
          (answer) => answer.questionId === node.question.id
        );
        if (!answer) {
          return sum;
        }
        if (!answer) {
          return sum;
        }
        return (
          sum +
          (node.weight * assertNonNull(answer.integerAnswer)) /
            totalQuestionWeight
        );
      }, 0);
  }, [proposedAnswers, questionNodes, totalQuestionWeight]);

  const [deleteAnswers] = useMutation<
    DeleteAssessmentAnswerMutationMutation,
    DeleteAssessmentAnswerMutationMutationVariables
  >(deleteAssessmentAnswerMutation);

  const [saveAssessment, { loading: isSavingAssessment }] = useMutation<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >(createOrUpdateAssessmentMutation);

  useEffect(() => {
    if (
      !assessment ||
      debouncedProposedAnswers.length === 0 ||
      isFirstPageLoad ||
      assessment.state === AssessmentState.Submitted
    ) {
      if (isFirstPageLoad) {
        setIsFirstPageLoad(false);
      }
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: assertNonNull(target).id,
        assessmentTemplateId: assertNonNull(assessmentTemplateId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: debouncedProposedAnswers,
      },
      onError,
    });
    // autosave, only fire to backend when debounced answers changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProposedAnswers]);

  const handleUpdateAnswer = useCallback(
    (newAnswer: AssessmentAnswer) => {
      const newAnswers = [
        ...proposedAnswers.filter(
          (answer) => newAnswer.questionId !== answer.questionId
        ),
        newAnswer,
      ];
      onChangeProposedAnswers(newAnswers);
    },
    [onChangeProposedAnswers, proposedAnswers]
  );

  const handleDeleteAnswers = useCallback(
    (questionIds: number[]) => {
      if (assessmentId) {
        const newAnswers = proposedAnswers.filter(
          (answer) => !questionIds.includes(answer.questionId)
        );
        onChangeProposedAnswers(newAnswers);
        deleteAnswers({
          variables: {
            questionIds,
            assessmentId: assessmentId,
          },
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [assessmentId, deleteAnswers, onChangeProposedAnswers, proposedAnswers]
  );

  const areAllQuestionsAnswered = useMemo(() => {
    const individualGoalBasedQuestions = questionNodes.filter(
      (node) =>
        node.question.__typename === "IndividualGoalAssessmentQuestionNode"
    );
    return questionNodes
      .filter((node) => node.question.isResponseMandatory)
      .every((node) => {
        const answer = proposedAnswers.find(
          (answer) => answer.questionId === node.question.id
        );
        // If there are no individual goal based questions, then the overall goal question can be considered answered
        if (
          node.question.questionType === AssessmentQuestionType.OverallGoal &&
          individualGoalBasedQuestions.length === 0
        )
          return true;
        return (
          answer &&
          (([
            AssessmentQuestionType.Range,
            AssessmentQuestionType.IndividualGoal,
            AssessmentQuestionType.OverallGoal,
            AssessmentQuestionType.Competency,
            AssessmentQuestionType.CompetencyCriteria,
            AssessmentQuestionType.Responsibility,
          ].includes(node.question.questionType) &&
            isInteger(answer.integerAnswer)) ||
            (node.question.questionType === AssessmentQuestionType.Text &&
              answer.textAnswer &&
              !isEmptyValue(answer.textAnswer)) ||
            (node.question.questionType ===
              AssessmentQuestionType.Multichoice &&
              answer.choices &&
              answer.choices.length > 0))
        );
      });
  }, [proposedAnswers, questionNodes]);

  const areAllCommentsEntered = useMemo(() => {
    return questionNodes
      .filter(
        (node) =>
          node.question.isCommentMandatory &&
          node.question.__typename !== "OverallGoalAssessmentQuestionNode" &&
          node.question.__typename !== "IndividualGoalAssessmentQuestionNode"
      )
      .every((node) => {
        const answer = proposedAnswers.find(
          (answer) => answer.questionId === node.question.id
        );
        return !answer || !isEmptyValue(answer.comment);
      });
  }, [proposedAnswers, questionNodes]);

  const handleSubmitAssessment = useCallback(() => {
    if (!areAllCommentsEntered || !areAllQuestionsAnswered) {
      errorNotificationVar({
        title: "Please answer all questions and comments",
      });
      setShowErrors(true);
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: assertNonNull(target).id,
        assessmentTemplateId: assertNonNull(assessmentTemplateId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: proposedAnswers,
        state: AssessmentState.Submitted,
      },
      refetchQueries: [getMyAssessmentsQuery, getPreviousAssessmentsQuery],
      onError,
      onCompleted: () => {
        const assessmentType = assertNonNull(
          assessment?.template.assessmentType
        );
        successNotificationVar({
          title: `${getAssessmentTypeLabel(
            assessmentType,
            isSelfAssessment
          )} ${label("review")} submitted`,
        });
        link.redirect("/assessments");
      },
    });
  }, [
    saveAssessment,
    assessmentId,
    isSelfAssessment,
    organization,
    target,
    assessmentTemplateId,
    complianceProgram,
    proposedAnswers,
    onError,
    assessment,
    link,
    areAllCommentsEntered,
    areAllQuestionsAnswered,
    label,
  ]);

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label("review", { capitalize: true })} not found
      </div>
    );
  }

  const canUpdate = assessment.canUpdate?.permission;
  const formDisabled =
    assessment.state === AssessmentState.Submitted || !canUpdate;

  return (
    <div>
      <AssessmentHeader
        myAssessmentsData={myAssessmentsData}
        backUrl={backUrl}
      />

      <div>
        {sectionNodes.map((section) => {
          const sectionQuestions = section.questions.filter(({ question }) =>
            questionNodes.map((q) => q.question.id).includes(question.id)
          );
          const hasVisibleQuestions = sectionQuestions.length > 0;
          if (!hasVisibleQuestions) {
            return null;
          }
          const heading = String(section.id).endsWith(
            "-goals"
          ) ? null : section.title ? (
            <div className="m-6">
              <div className="font-bold text-xl">{section.title}</div>
              {section.description && !isEmptyValue(section.description) && (
                <TextareaWysiwyg
                  editable={false}
                  className="mt-1 bg-white"
                  value={section.description}
                />
              )}
            </div>
          ) : null;

          const regularQuestions = sectionQuestions.filter(
            ({ question }) =>
              !isRoleBasedQuestionType(question.questionType) &&
              !isGoalBasedQuestionType(question.questionType)
          );

          const goalBasedQuestions = sectionQuestions.filter(({ question }) =>
            isGoalBasedQuestionType(question.questionType)
          );
          const overallGoalBasedQuestion = goalBasedQuestions.find(
            ({ question }) =>
              question.questionType === AssessmentQuestionType.OverallGoal
          );
          const individualGoalBasedQuestions = goalBasedQuestions.filter(
            ({ question }) =>
              question.questionType === AssessmentQuestionType.IndividualGoal
          );

          return (
            <Fragment key={section.id}>
              {heading}
              {regularQuestions.map((questionWithWeight, index) => {
                const { question, weight } = questionWithWeight;
                if (
                  question.responses ===
                    AssessmentQuestionResponses.ExcludeSelfAssessment &&
                  isSelfAssessment
                ) {
                  return null;
                }
                if (
                  question.responses ===
                    AssessmentQuestionResponses.SelfAssessmentOnly &&
                  !isSelfAssessment
                ) {
                  return null;
                }
                const answer = proposedAnswers.find(
                  (answer) => answer.questionId === question.id
                );
                return (
                  <AssessmentQuestionItem
                    key={question.id}
                    answer={answer}
                    question={question}
                    weight={weight}
                    totalQuestionWeight={totalQuestionWeight}
                    index={index}
                    onUpdateAnswer={handleUpdateAnswer}
                    formDisabled={formDisabled}
                    showError={showErrors}
                    isQuestionWeightingEnabled={
                      assessment.template.isQuestionWeightingEnabled
                    }
                  />
                );
              })}

              {String(section.id).endsWith("-goals") && (
                <GoalBasedQuestions
                  overallGoalBasedQuestion={overallGoalBasedQuestion}
                  individualGoalBasedQuestions={individualGoalBasedQuestions}
                  isSelfAssessment={isSelfAssessment}
                  proposedAnswers={proposedAnswers}
                  formDisabled={formDisabled}
                  showErrors={showErrors}
                  isQuestionWeightingEnabled={
                    assessment.template.isQuestionWeightingEnabled
                  }
                  totalQuestionWeight={totalQuestionWeight}
                  onUpdateAnswer={handleUpdateAnswer}
                />
              )}

              <AssessmentRoleBasedSection
                sectionQuestions={sectionQuestions}
                targetCurrentRoleIds={compact(assessment.targetCurrentRoleIds)}
                onUpdateAnswer={handleUpdateAnswer}
                onDeleteAnswers={handleDeleteAnswers}
                formDisabled={formDisabled}
                showErrors={showErrors}
                allAnswers={proposedAnswers}
                nextRoleAssessmentEnabled={
                  (!isSelfAssessment &&
                    !!myAssessmentsData?.assessment?.template
                      .roleBasedAssessmentsAssessNextRole) ||
                  (isSelfAssessment &&
                    !!myAssessmentsData?.assessment?.template
                      .roleBasedAssessmentsSelfAssessNextRole)
                }
                isSelfAssessment={isSelfAssessment}
              />
            </Fragment>
          );
        })}
      </div>

      <div className="flex items-center justify-between mt-4 pb-6 px-6">
        <div className="text-sm text-gray-500">
          {assessment.template.isQuestionWeightingEnabled && (
            <span>Total weighted score: {totalWeightedScore.toFixed(1)}</span>
          )}
        </div>
        {!formDisabled && (
          <div className="flex items-center justify-end gap-2">
            {isSavingAssessment && <Loading mini size="4" />}
            <Button
              onClick={() => {
                successNotificationVar({
                  title: `${getAssessmentTypeLabel(
                    assertNonNull(assessment.template.assessmentType),
                    isSelfAssessment
                  )} ${label("review")} saved for later`,
                });
                link.redirect("/assessments");
              }}
              theme={buttonTheme.text}
              disabled={isSavingAssessment}
            >
              Save as draft
            </Button>
            <Button
              disabled={isSavingAssessment}
              type="button"
              theme={buttonTheme.primary}
              onClick={handleSubmitAssessment}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentRightCol;
