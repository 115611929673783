import { useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import moment from "moment";
import {
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
} from "types/graphql-schema";

import getAssessmentDelivery from "@apps/assessments/graphql/get-assessment-delivery";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import CloseButton from "@components/close-button/close-button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Sidebar from "@components/sidebar/sidebar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";

import { AssessmentDeliveryContent } from "../delivery/assessment-delivery";

const AssessmentSidebar = ({
  assessmentDeliveryId,
  onClose,
}: {
  assessmentDeliveryId: number;
  onClose: () => void;
}) => {
  const orgranization = currentOrganizationVar();
  const label = useLabel();

  const { data, loading } = useQuery<
    GetAssessmentDeliveryQuery,
    GetAssessmentDeliveryQueryVariables
  >(getAssessmentDelivery, {
    fetchPolicy: "no-cache",
    variables: { assessmentDeliveryId, organizationId: orgranization.id },
    onError: onNotificationErrorHandler(),
  });
  const assessmentDelivery = data?.assessmentDelivery;
  const excludedAnswerIds = (data?.assessmentDelivery?.excludedAnswerIds ||
    []) as number[];

  return (
    <>
      <Sidebar show onClose={onClose}>
        {loading ? (
          <Loading>Loading assessment delivery...</Loading>
        ) : assessmentDelivery ? (
          <div className="px-4 sm:px-6 flex flex-col gap-8 text-sm">
            <div className="flex justify-between items-center">
              <AppLink
                target="_blank"
                to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
                className="font-medium text-xl tracking-tight hover:underline"
              >
                {getAssessmentTypeLabel(
                  assessmentDelivery.template.assessmentType
                )}{" "}
                {label("review")} delivered to{" "}
                {assessmentDelivery.target?.firstName}
                <ExternalLinkIcon className="h-4 w-4 ml-1 text-gray-500 inline-block" />
              </AppLink>
              <CloseButton onClick={onClose} />
            </div>
            <div className="w-full gap-2 flex flex-col">
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Subject:
                </div>
                <div className="flex items-center gap-1">
                  <Avatar user={assessmentDelivery.target} size="4" />
                  {assessmentDelivery.target?.name}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Type:
                </div>
                <div className="flex items-center gap-1">
                  {getAssessmentTypeLabel(
                    assessmentDelivery.template.assessmentType
                  )}{" "}
                  {label("review")}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Program:
                </div>
                <div>
                  {assessmentDelivery.complianceProgram?.title} due on{" "}
                  {moment(assessmentDelivery.dueDate).format("MMM D, YYYY")}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Delivery:
                </div>
                <div>
                  {assessmentDelivery.deliveryDatetime
                    ? `Delivered by ${
                        assessmentDelivery.creator?.name
                      } on ${moment(assessmentDelivery.deliveryDatetime).format(
                        "MMM D, YYYY @ h:mma"
                      )}`
                    : "Immediate access"}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-30 font-medium text-gray-500 tracking-tight">
                  Link:
                </div>
                <div className="flex items-center gap-2">
                  <AppLink
                    to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
                    className="text-blue-link hover:underline flex items-center gap-1"
                    target="_blank"
                  >
                    <ExternalLinkIcon className="h-4 w-4" />
                    View delivery package
                  </AppLink>
                </div>
              </div>
            </div>
            <div>
              <div className="border-b pb-0.5">
                <span className="font-medium text-base">Preview</span>
              </div>

              <div className="flex flex-col gap-6 mt-2">
                <AssessmentDeliveryContent
                  assessmentDeliveryData={data}
                  canUpdate={false}
                  isDraft={false}
                  summary={assessmentDelivery.summary}
                  showHiddenQuestions={false}
                  excludedAnswerIds={excludedAnswerIds}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="px-4 sm:px-6 flex flex-col gap-6 text-sm text-gray-500">
            Assessment delivery not found.
          </div>
        )}
      </Sidebar>
    </>
  );
};

export default AssessmentSidebar;
