import { useMemo } from "react";
import {
  AssessmentGroupDelivery,
  AssessmentQuestionType,
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { CareerTrackRolePickerRole } from "@apps/org-settings/components/competencies/career-track-role-picker";
import {
  matchesReportsToFilter,
  matchesRoleFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import Table, {
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { PerformanceAssessmentAnswerFilter } from "../assessment-report";
import { TeamPickerTeam } from "../team-picker";
import PerformanceAssessmentTableRow from "./performance-assessment-table-row";

export type PerformanceAssessmentTableQuestion = Pick<
  RangeAssessmentQuestionNode,
  | "id"
  | "title"
  | "startValue"
  | "endValue"
  | "labels"
  | "questionType"
  | "labelDescriptions"
>;

const PerformanceAssessmentTable = ({
  questions,
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  answerFilterList,
  teamFilterList,
  roleFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: PerformanceAssessmentTableQuestion[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
  roleFilterList: CareerTrackRolePickerRole[];
  answerFilterList: PerformanceAssessmentAnswerFilter[];
}) => {
  const label = useLabel();
  const isAdmin = isAdminVar();
  const assessmentTemplate = assertNonNull(
    complianceProgram.performanceAssessmentTemplate
  );

  const assessments = useMemo(() => {
    return assertEdgesNonNull(complianceProgram.assessments).filter(
      (assessment) => assessment.template.id === assessmentTemplate.id
    );
  }, [assessmentTemplate, complianceProgram]);

  const showRoleColumn = useMemo(() => {
    return (
      waffle.flag_is_active("calibration") &&
      assessments.some((assessment) => {
        return assessment.currentRoles.edges.length > 0;
      })
    );
  }, [assessments]);

  if (assessmentTemplate.isOnlySelfAssessment) {
    return (
      <div className="mx-auto my-12 text-center text-gray-400">
        {`This performance ${label(
          "review"
        )} only contains self assessment questions. There is no data to display.`}
      </div>
    );
  }
  const showAdminApprovalStatus =
    waffle.flag_is_active("calibration") &&
    assessmentTemplate.delivery === AssessmentGroupDelivery.AdminApproval &&
    isAdmin;

  return (
    <TableContainer scroll>
      <Table>
        <TableHeadRow>
          <TableHeadCell>Name</TableHeadCell>
          {showRoleColumn && <TableHeadCell>Current Role</TableHeadCell>}
          <TableHeadCell>Manager</TableHeadCell>
          <TableHeadCell>
            {label("team", { pluralize: true, capitalize: true })}
          </TableHeadCell>
          {questions.map((question) => {
            if (question.questionType === AssessmentQuestionType.Text) {
              return null;
            }
            return (
              <TableHeadCell key={question.id}>{question.title}</TableHeadCell>
            );
          })}
          {showAdminApprovalStatus && <TableHeadCell>Status</TableHeadCell>}
        </TableHeadRow>
        <TableBody>
          {assessments.map((assessment) => {
            const response = assessment;
            const responder = assertNonNull(response.responder);
            const target = assertNonNull(response.target);
            const answers = assertEdgesNonNull(response.answers);

            if (!matchesUserFilter(userFilterList, target)) {
              return null;
            }
            if (!matchesReportsToFilter(reportsToFilterList, target)) {
              return null;
            }
            if (!matchesTeamsFilter(teamFilterList, target)) {
              return null;
            }
            if (!matchesRoleFilter(roleFilterList, assessment)) {
              return null;
            }
            if (responder.id === target.id) {
              // don't show self assessments for now
              return null;
            }
            if (
              answerFilterList.length > 0 &&
              !answerFilterList.every(
                (answerFilter) =>
                  !!answers.find(
                    (a) =>
                      a.question.id === answerFilter.questionId &&
                      a.__typename === "RangeAssessmentAnswerNode" &&
                      a.integerAnswer === answerFilter.integerAnswer
                  )
              )
            ) {
              return null;
            }
            return (
              <PerformanceAssessmentTableRow
                key={response.id}
                assessment={response}
                target={target}
                responder={responder}
                answers={answers}
                questions={questions}
                assessmentTemplate={assessmentTemplate}
                showRoleColumn={showRoleColumn}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceAssessmentTable;
