import { ExternalLinkIcon } from "@heroicons/react/outline";
import {
  AssessmentDeliveryState,
  AssessmentGroupDelivery,
  ComplianceProgramReportAnswerFragment,
  ComplianceProgramReportAssessmentFragment,
  ComplianceProgramTargetFragment,
  GetComplianceProgramForReportQuery,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import { assertEdgesNonNull } from "@helpers/helpers";

import { PerformanceAssessmentTableQuestion } from "./performance-assessment-table";
import PerformanceAssessmentTableRowAnswerCell from "./performance-assessment-table-row-answer-cell";

const PerformanceAssessmentTableRow = ({
  assessment,
  target,
  responder,
  answers,
  questions,
  assessmentTemplate,
  showRoleColumn,
}: {
  assessment: ComplianceProgramReportAssessmentFragment;
  target: ComplianceProgramTargetFragment;
  responder: BasicUser;
  answers: ComplianceProgramReportAnswerFragment[];
  questions: PerformanceAssessmentTableQuestion[];
  assessmentTemplate: NonNullable<
    NonNullable<
      GetComplianceProgramForReportQuery["complianceProgram"]
    >["performanceAssessmentTemplate"]
  >;
  showRoleColumn: boolean;
}) => {
  const label = useLabel();
  const isAdmin = isAdminVar();
  const teams = assertEdgesNonNull(target.teams);
  const currentRoles = assertEdgesNonNull(assessment.currentRoles);
  const hasAdminCalibration =
    waffle.flag_is_active("calibration") &&
    isAdmin &&
    assessmentTemplate.delivery === AssessmentGroupDelivery.AdminApproval;
  const canAdminCalibrate =
    hasAdminCalibration &&
    assessment.assessmentDelivery?.state ===
      AssessmentDeliveryState.AwaitingAdminApproval;
  return (
    <TableBodyRow key={assessment.id}>
      <TableBodyCell>
        <div className="flex items-center">
          <Avatar className="mr-2" size="6" user={target} /> {target.name}
        </div>
      </TableBodyCell>
      {showRoleColumn && (
        <TableBodyCell>
          {currentRoles
            .map((role) => `${role.title} (${role.careerTrack.title})`)
            .join(", ")}
        </TableBodyCell>
      )}
      <TableBodyCell>{responder.name}</TableBodyCell>
      <TableBodyCell>
        {teams.map(({ title }) => title).join(", ")}
      </TableBodyCell>
      {questions.map((question) => {
        return (
          <PerformanceAssessmentTableRowAnswerCell
            key={question.id}
            question={question}
            answers={answers}
            responder={responder}
            canAdminCalibrate={canAdminCalibrate}
          />
        );
      })}
      {hasAdminCalibration && (
        <TableBodyCell>
          <div className="flex items-center gap-1">
            {!!assessment.assessmentDelivery ? (
              <>
                <span className="capitalize">
                  {assessment.assessmentDelivery.state ===
                  AssessmentDeliveryState.Draft
                    ? "Draft"
                    : assessment.assessmentDelivery.state ===
                      AssessmentDeliveryState.AwaitingAdminApproval
                    ? "Awaiting Admin Approval"
                    : "Approved"}
                </span>
                <Button
                  tooltip={`View ${label("assessment")}`}
                  icon={ExternalLinkIcon}
                  to={`/assessments/assessment/delivery/${assessment.assessmentDelivery.id}`}
                  theme={buttonTheme.iconGray}
                  size={ButtonSize.small}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </TableBodyCell>
      )}
    </TableBodyRow>
  );
};

export default PerformanceAssessmentTableRow;
