import { useMutation, useQuery } from "@apollo/client";
import { compact, noop, omit } from "lodash";
import moment from "moment";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupProviders,
  AssessmentTemplateComplianceRequirement,
  AssessmentType,
  CompetencyAssessmentType,
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  ComplianceProgramState,
  GetAssessmentGroupsQuery,
  GetAssessmentGroupsQueryVariables,
  GetComplianceProgramAdminQuery,
  GetComplianceProgramAdminQueryVariables,
  GetTopicTemplatesQuery,
  GetTopicTemplatesQueryVariables,
  Maybe,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
  TopicTemplateComplianceRequirement,
} from "types/graphql-schema";
import { PastOnlyDateRangeEnum } from "types/topicflow";

import RecipientForm from "@apps/artifact/components/recipient-form";
import TeamPicker from "@apps/reporting/components/team-picker";
import getTemplatesQuery from "@apps/templates/graphql/get-templates-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import {
  assessmentGroupAnonymityOptions,
  assessmentGroupDeliveryOptions,
  assessmentGroupProviderOptions,
  getAssessmentTypeLabel,
} from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  dateRangeToDateArray,
} from "@helpers/helpers";

import getAssessmentGroupsQuery from "../../assessments/graphql/get-assessment-groups-query";
import createOrUpdateComplianceProgramMutation from "../graphql/create-or-update-compliance-program-mutation";
import getComplianceProgramAdminQuery from "../graphql/get-compliance-program-admin-query";
import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";
import IncludeRoleBasedForm from "./compliance-program-form/include-role-based-form";

const complianceProgramUrl = "/programs";

const appliesToOptions = [
  { value: ComplianceProgramAppliesTo.Organization, label: "Organization" },
  { value: ComplianceProgramAppliesTo.Departments, label: "Departments" },
  { value: ComplianceProgramAppliesTo.Managers, label: "Managers" },
  { value: ComplianceProgramAppliesTo.Users, label: "Users" },
];

enum ExtraDateRangeEnum {
  none = "none",
  custom = "custom",
}
type DateRangeType = ExtraDateRangeEnum | PastOnlyDateRangeEnum;

export type AssessmentTemplateExtraSettings = Partial<
  Pick<
    AssessmentTemplateComplianceRequirement,
    | "anonymity"
    | "providers"
    | "delivery"
    | "enableRoleBasedAssessments"
    | "enableGoalAssessments"
    | "roleBasedAssessmentsAssessNextRole"
    | "roleBasedAssessmentsSelfAssessNextRole"
    | "competencyAssessmentType"
    | "nextRoleCompetencyAssessmentType"
    | "roleBasedAssessmentScale"
    | "roleBasedAssessmentScaleLabels"
    | "roleBasedAssessmentScaleStartsAt"
  >
>;

const ComplianceProgramEdit = () => {
  const link = useLink();
  const location = useLocation();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const { complianceProgramId: complianceProgramIdParam } = useParams<{
    complianceProgramId: string;
  }>();
  const complianceProgramId = parseInt(complianceProgramIdParam);
  const isNew = isNaN(complianceProgramId);
  const isDuplicate = !isNew && location.pathname.includes("/duplicate");
  const [proposedComplianceProgram, setProposedComplianceProgram] =
    useState<SaveComplianceProgramMutationVariables>({
      appliesTo: ComplianceProgramAppliesTo.Organization,
      appliesToTeams: [],
      recurrence: ComplianceProgramRecurrence.None,
    });
  const [selectedProgramPeriodRange, setSelectedProgramPeriodRange] =
    useState<DateRangeType>(ExtraDateRangeEnum.none);
  const [
    availableAssessmentGroupSelectOptions,
    setAvailableAssessmentGroupSelectOptions,
  ] = useState<ComboboxGenericOption<number>[]>([]);
  // Need special handling for peer assessment, since it doesn't allow self assesment
  // we need to exclude groups with only self assessment questions
  const [
    availablePeerAssessmentGroupSelectOptions,
    setAvailablePeerAssessmentGroupSelectOptions,
  ] = useState<ComboboxGenericOption<number>[]>([]);
  const [availableTopicTemplates, setAvailableTopicTemplates] = useState<
    ComboboxGenericOption<number>[]
  >([]);
  const [appliesToTeams, setAppliesToTeams] = useState<
    { id: number; title: string }[]
  >([]);
  const [appliesToManagers, setAppliesToManagers] = useState<
    { id: number; name: string }[]
  >([]);
  const [appliesToUsers, setAppliesToUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [excludedUsers, setExcludedUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [canUpdateTemplates, setCanUpdateTemplates] = useState(isNew);

  const homeUrl = useMemo(
    () =>
      isNew || isDuplicate
        ? complianceProgramUrl
        : `${complianceProgramUrl}/${complianceProgramId}`,
    [complianceProgramId, isNew, isDuplicate]
  );

  const [
    createOrUpdateComplianceProgram,
    { loading: isSavingComplianceProgram },
  ] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const { loading: isLoadingComplianceProgram } = useQuery<
    GetComplianceProgramAdminQuery,
    GetComplianceProgramAdminQueryVariables
  >(getComplianceProgramAdminQuery, {
    variables: { complianceProgramId },
    skip: isNew,
    onError: onNotificationErrorHandler(),
    onCompleted: (response) => {
      if (!response.complianceProgram) {
        link.redirect(homeUrl);
      } else {
        const topicTemplates = assertEdgesNonNull(
          response.complianceProgram.requiredTopicTemplates
        );
        const assessmentTemplates = compact([
          response.complianceProgram.performanceAssessmentTemplate && {
            id: response.complianceProgram.performanceAssessmentTemplate.id,
            assessmentType: AssessmentType.Performance,
            anonymity:
              response.complianceProgram.performanceAssessmentTemplate
                .anonymity,
            providers:
              response.complianceProgram.performanceAssessmentTemplate
                .providers,
            delivery:
              response.complianceProgram.performanceAssessmentTemplate.delivery,
            enableRoleBasedAssessments:
              response.complianceProgram.performanceAssessmentTemplate
                .enableRoleBasedAssessments,
            enableGoalAssessments:
              response.complianceProgram.performanceAssessmentTemplate
                .enableGoalAssessments,
            roleBasedAssessmentsAssessNextRole:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentsAssessNextRole,
            roleBasedAssessmentsSelfAssessNextRole:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentsSelfAssessNextRole,
            competencyAssessmentType:
              response.complianceProgram.performanceAssessmentTemplate
                .competencyAssessmentType,
            nextRoleCompetencyAssessmentType:
              response.complianceProgram.performanceAssessmentTemplate
                .nextRoleCompetencyAssessmentType,
            roleBasedAssessmentScale:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScale,
            roleBasedAssessmentScaleLabels:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScaleLabels,
            roleBasedAssessmentScaleStartsAt:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScaleStartsAt,
            questionSetIds:
              response.complianceProgram.performanceAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
          response.complianceProgram.managerAssessmentTemplate && {
            id: response.complianceProgram.managerAssessmentTemplate.id,
            assessmentType: AssessmentType.Manager,
            anonymity:
              response.complianceProgram.managerAssessmentTemplate.anonymity,
            providers:
              response.complianceProgram.managerAssessmentTemplate.providers,
            delivery:
              response.complianceProgram.managerAssessmentTemplate.delivery,
            questionSetIds:
              response.complianceProgram.managerAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
          response.complianceProgram.peerAssessmentTemplate && {
            id: response.complianceProgram.peerAssessmentTemplate.id,
            assessmentType: AssessmentType.Peer,
            anonymity:
              response.complianceProgram.peerAssessmentTemplate.anonymity,
            providers:
              response.complianceProgram.peerAssessmentTemplate.providers,
            delivery:
              response.complianceProgram.peerAssessmentTemplate.delivery,
            questionSetIds:
              response.complianceProgram.peerAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
        ]);
        setAppliesToTeams(
          assertEdgesNonNull(response.complianceProgram.appliesToTeams)
        );
        setAppliesToManagers(
          assertEdgesNonNull(response.complianceProgram.appliesToManagers)
        );
        setAppliesToUsers(
          assertEdgesNonNull(response.complianceProgram.appliesToUsers)
        );
        setExcludedUsers(
          assertEdgesNonNull(response.complianceProgram.excludedUsers)
        );

        const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
          (dateRange) => {
            const dates = dateRangeToDateArray({
              range: dateRange,
              quarterStartMonth: organization.quarterStartMonth,
            });
            return {
              value: dateRange,
              startDate: dates[0],
              endDate: dates[1],
            };
          }
        );
        const programHasPeriod =
          response.complianceProgram.periodStartDate !== null &&
          response.complianceProgram.periodEndDate !== null;
        const selectedPeriodEnum = programHasPeriod
          ? dateRangeOptions.find(({ startDate, endDate }) => {
              return (
                startDate === response.complianceProgram?.periodStartDate &&
                endDate === response.complianceProgram?.periodEndDate
              );
            })
          : null;

        setProposedComplianceProgram({
          ...response.complianceProgram,
          state: isDuplicate
            ? ComplianceProgramState.Draft
            : response.complianceProgram.state,
          complianceProgramId: response.complianceProgram.id,
          topicTemplates: topicTemplates.map(({ id }) => ({ id })),
          assessmentTemplates: isDuplicate
            ? assessmentTemplates.map((template) => omit(template, "id"))
            : assessmentTemplates,
          appliesTo: response.complianceProgram.appliesTo,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
          periodStartDate: response.complianceProgram.periodStartDate,
          periodEndDate: response.complianceProgram.periodEndDate,
        });
        setSelectedProgramPeriodRange(
          selectedPeriodEnum
            ? selectedPeriodEnum.value
            : programHasPeriod
            ? ExtraDateRangeEnum.custom
            : ExtraDateRangeEnum.none
        );

        setCanUpdateTemplates(
          isDuplicate ||
            response.complianceProgram.state === ComplianceProgramState.Draft
        );
      }
    },
  });

  const { loading: isLoadingAvailableAssessmentGroups } = useQuery<
    GetAssessmentGroupsQuery,
    GetAssessmentGroupsQueryVariables
  >(getAssessmentGroupsQuery, {
    variables: {
      organizationId: organization.id,
      archived: canUpdateTemplates ? false : null,
    },
    onCompleted: (response) => {
      const groups = response.assessmentGroups
        ? assertEdgesNonNull(response.assessmentGroups)
        : [];
      setAvailableAssessmentGroupSelectOptions(
        groups.map(({ id, title }) => ({ value: id, label: title }))
      );
      setAvailablePeerAssessmentGroupSelectOptions(
        groups
          .filter(({ isOnlySelfAssessment }) => !isOnlySelfAssessment)
          .map(({ id, title }) => ({ value: id, label: title }))
      );
    },
    onError: onNotificationErrorHandler(),
  });

  const { loading: isLoadingAvailableTopicTemplates } = useQuery<
    GetTopicTemplatesQuery,
    GetTopicTemplatesQueryVariables
  >(getTemplatesQuery, {
    onCompleted: (response) => {
      const templates = response.topicTemplates
        ? assertEdgesNonNull(response.topicTemplates)
        : [];
      setAvailableTopicTemplates(
        templates.map(({ id, title }) => ({ value: id, label: title }))
      );
    },
    onError: onNotificationErrorHandler(),
  });

  const handleSaveAssessmentGroup = useCallback(
    (state: ComplianceProgramState) => {
      createOrUpdateComplianceProgram({
        variables: {
          ...proposedComplianceProgram,
          topicTemplates: canUpdateTemplates
            ? proposedComplianceProgram.topicTemplates
            : undefined,
          assessmentTemplates: canUpdateTemplates
            ? proposedComplianceProgram.assessmentTemplates
            : undefined,
          complianceProgramId:
            isNew || isDuplicate
              ? undefined
              : proposedComplianceProgram.complianceProgramId,
          organizationId: organization.id,
          state: state,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
        },
        onError,
        refetchQueries: [getComplianceProgramsQuery],
        onCompleted: () => {
          successNotificationVar({
            title:
              state === ComplianceProgramState.Draft
                ? "Program saved for later"
                : "Program published",
          });
          link.redirect(homeUrl);
        },
      });
    },
    [
      appliesToManagers,
      appliesToTeams,
      appliesToUsers,
      excludedUsers,
      createOrUpdateComplianceProgram,
      homeUrl,
      link,
      onError,
      organization,
      proposedComplianceProgram,
      isNew,
      isDuplicate,
      canUpdateTemplates,
    ]
  );

  const selectedTopicTemplateId = useMemo(
    () =>
      proposedComplianceProgram.topicTemplates
        ? (proposedComplianceProgram.topicTemplates as Maybe<TopicTemplateComplianceRequirement>[])
        : [],
    [proposedComplianceProgram.topicTemplates]
  );

  const selectedAssessmentTemplates = useMemo(
    () =>
      proposedComplianceProgram.assessmentTemplates
        ? compact(
            proposedComplianceProgram.assessmentTemplates as Maybe<AssessmentTemplateComplianceRequirement>[]
          )
        : [],
    [proposedComplianceProgram.assessmentTemplates]
  );

  const selectedPerformanceAssessmentGroup = useMemo(() => {
    return selectedAssessmentTemplates.find(
      (template) => template.assessmentType === AssessmentType.Performance
    );
  }, [selectedAssessmentTemplates]);
  const selectedManagerAssessmentGroup = useMemo(() => {
    return selectedAssessmentTemplates.find(
      (template) => template.assessmentType === AssessmentType.Manager
    );
  }, [selectedAssessmentTemplates]);
  const selectedPeerAssessmentGroup = useMemo(() => {
    return selectedAssessmentTemplates.find(
      (template) => template.assessmentType === AssessmentType.Peer
    );
  }, [selectedAssessmentTemplates]);

  const handleChangeAssessmentTemplate = useCallback(
    (assessmentType: AssessmentType) => (opt: ComboboxGenericOption<number>) => {
      const existingTemplate = proposedComplianceProgram.assessmentTemplates
        ? (
            proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
          ).find((template) => template.assessmentType === assessmentType)
        : undefined;

      const groupSettings = {
        anonymity: AssessmentGroupAnonymity.NotAnonymous,
        providers: AssessmentGroupProviders.Default,
        delivery: AssessmentGroupDelivery.Full,
        enableRoleBasedAssessments: false,
        enableGoalAssessments: false,
        roleBasedAssessmentsAssessNextRole: false,
        roleBasedAssessmentsSelfAssessNextRole: false,
        competencyAssessmentType: CompetencyAssessmentType.CriteriaCombined,
        nextRoleCompetencyAssessmentType:
          CompetencyAssessmentType.CriteriaCombined,
        roleBasedAssessmentScale: 3,
        roleBasedAssessmentScaleStartsAt: 1,
        roleBasedAssessmentScaleLabels: ["", "", ""],
      };
      const newAssessmentTemplates = existingTemplate
        ? (
            proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
          ).map((template) => {
            if (template.assessmentType === assessmentType) {
              return {
                ...template,
                ...groupSettings,
                questionSetIds: [opt.value],
              };
            }
            return template;
          })
        : [
            ...((proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]) ??
              []),
            {
              assessmentType: assessmentType,
              ...groupSettings,
              questionSetIds: [opt.value],
            },
          ];
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        assessmentTemplates: compact(newAssessmentTemplates),
      });
    },
    [proposedComplianceProgram]
  );

  const handleChangeAssessmentTemplateSettings = useCallback(
    (assessmentType: AssessmentType) =>
      (settings: AssessmentTemplateExtraSettings) => {
        const existingTemplate = proposedComplianceProgram.assessmentTemplates
          ? (
              proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
            ).find((template) => template.assessmentType === assessmentType)
          : undefined;
        if (!existingTemplate) {
          return;
        }
        const newAssessmentTemplates = (
          proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
        ).map((template) => {
          if (template.assessmentType === assessmentType) {
            const newTemplate = {
              ...template,
              ...settings,
            };

            // keep the scale and number of labels in sync
            if (
              !newTemplate.roleBasedAssessmentScaleLabels ||
              !newTemplate.roleBasedAssessmentScale
            ) {
              throw new Error(
                "Expecting Role based assessment scale and labels to be set"
              );
            }
            if (
              newTemplate.roleBasedAssessmentScaleLabels.length >
              newTemplate.roleBasedAssessmentScale
            ) {
              newTemplate.roleBasedAssessmentScaleLabels =
                newTemplate.roleBasedAssessmentScaleLabels.slice(
                  0,
                  newTemplate.roleBasedAssessmentScale
                );
            } else if (
              newTemplate.roleBasedAssessmentScaleLabels.length <
              newTemplate.roleBasedAssessmentScale
            ) {
              newTemplate.roleBasedAssessmentScaleLabels =
                newTemplate.roleBasedAssessmentScaleLabels.concat(
                  Array(
                    newTemplate.roleBasedAssessmentScale -
                      newTemplate.roleBasedAssessmentScaleLabels.length
                  ).fill("")
                );
            }

            return newTemplate;
          }
          return template;
        });
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          assessmentTemplates: newAssessmentTemplates,
        });
      },
    [proposedComplianceProgram]
  );

  const handleEnableRecurrence: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (evt) => {
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          recurrence: evt.target.checked
            ? ComplianceProgramRecurrence.Monthly
            : ComplianceProgramRecurrence.None,
        });
      },
      [proposedComplianceProgram]
    );

  const handleEnableOngoing: ChangeEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        ongoing: evt.target.checked,
        recurrence: evt.target.checked
          ? ComplianceProgramRecurrence.None
          : proposedComplianceProgram.recurrence,
      });
    },
    [proposedComplianceProgram]
  );

  const handleChangeRecurrence = useCallback(
    (opt: SelectOption<ComplianceProgramRecurrence>) => {
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        recurrence: opt.value,
      });
    },
    [proposedComplianceProgram]
  );

  const label = useLabel();

  const deliveryOptions = useMemo(
    () => assessmentGroupDeliveryOptions(label),
    [label]
  );

  const programPeriodOptions = useMemo(() => {
    const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
      (dateRange) => ({
        value: dateRange,
        label: label(dateRange, { capitalize: true }),
        description: dateRangeToDateArray({
          range: dateRange,
          quarterStartMonth: organization.quarterStartMonth,
        })
          .map((date) => moment(date).format("ll"))
          .join(" - "),
      })
    );
    return [
      {
        value: ExtraDateRangeEnum.none,
        label: "Not set",
      },
      ...dateRangeOptions,
      {
        value: ExtraDateRangeEnum.custom,
        label: "Custom date range",
      },
    ];
  }, [label, organization]);

  const handleChangeProgramPeriod = useCallback(
    (option: SelectOption<DateRangeType>) => {
      if (option.value === ExtraDateRangeEnum.none) {
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: null,
          periodEndDate: null,
          removeProgramPeriod: true,
        });
      } else if (option.value !== ExtraDateRangeEnum.custom) {
        const dates = dateRangeToDateArray({
          range: option.value,
          quarterStartMonth: organization.quarterStartMonth,
        });
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: dates[0],
          periodEndDate: dates[1],
          removeProgramPeriod: undefined,
        });
      }
      setSelectedProgramPeriodRange(option.value);
    },
    [organization, proposedComplianceProgram]
  );

  if (
    isLoadingComplianceProgram ||
    isLoadingAvailableAssessmentGroups ||
    isLoadingAvailableTopicTemplates
  ) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading program..</Loading>
      </div>
    );
  }

  const canSaveTooltip =
    !proposedComplianceProgram.title ||
    proposedComplianceProgram.title.trim().length === 0
      ? "Please enter a title"
      : !proposedComplianceProgram.startDate &&
        !proposedComplianceProgram.startDateOffset
      ? "Please enter a start date"
      : !proposedComplianceProgram.dueDate &&
        !proposedComplianceProgram.startDateDueDateOffset
      ? "Please enter a due date"
      : selectedProgramPeriodRange === ExtraDateRangeEnum.custom &&
        (!proposedComplianceProgram.periodStartDate ||
          !proposedComplianceProgram.periodEndDate)
      ? "Please enter custom period dates"
      : null;

  return (
    <form className="p-6 flex-1" aria-label="Compliance program form">
      <div className="flex items-center justify-between mb-4">
        <div className="text-xl font-medium">
          {isNew ? "New" : isDuplicate ? "Duplicate" : "Edit"} program
        </div>
        <div className="flex justify-end items-center gap-2 sm:gap-4">
          <Button
            to={homeUrl}
            theme={buttonTheme.text}
            disabled={isSavingComplianceProgram}
          >
            Discard changes
          </Button>
          {proposedComplianceProgram.state !==
            ComplianceProgramState.Published && (
            <Button
              type="button"
              onClick={() =>
                handleSaveAssessmentGroup(ComplianceProgramState.Draft)
              }
              tooltip={canSaveTooltip}
              disabled={!!canSaveTooltip || isSavingComplianceProgram}
              theme={buttonTheme.lightBlue}
            >
              {`${isSavingComplianceProgram ? "Saving" : "Save"} for later`}
            </Button>
          )}
          <Button
            type="button"
            onClick={() =>
              handleSaveAssessmentGroup(ComplianceProgramState.Published)
            }
            tooltip={canSaveTooltip}
            disabled={!!canSaveTooltip || isSavingComplianceProgram}
            theme={buttonTheme.primary}
          >
            {proposedComplianceProgram.state !==
            ComplianceProgramState.Published
              ? "Save and publish"
              : "Save changes"}
          </Button>
        </div>
      </div>
      <div className="sm:flex">
        <div className="flex flex-col text-sm gap-2 w-full">
          <div className="w-96 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Title
            </div>
            <Input
              aria-label="Compliance program title input"
              value={proposedComplianceProgram.title ?? ""}
              onChange={(e) =>
                setProposedComplianceProgram({
                  ...proposedComplianceProgram,
                  title: e.target.value,
                })
              }
            />
          </div>
          {waffle.flag_is_active("ongoing-programs") && (
            <div className="h-12 flex items-center gap-2">
              <input
                type="checkbox"
                checked={proposedComplianceProgram.ongoing || false}
                onChange={handleEnableOngoing}
                disabled={
                  proposedComplianceProgram.state ===
                  ComplianceProgramState.Published
                }
              />{" "}
              Ongoing
            </div>
          )}

          {!proposedComplianceProgram.ongoing && (
            <div>
              <div className="w-96 flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Start date
                </div>
                <DatePicker
                  selected={
                    proposedComplianceProgram.startDate
                      ? moment(proposedComplianceProgram.startDate).toDate()
                      : null
                  }
                  onChange={(date) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      startDate: date
                        ? moment(date).format("YYYY-MM-DD")
                        : null,
                    })
                  }
                  dateFormat="MMM d, yyyy"
                  ariaLabelledBy="Compliance program start date"
                  className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="w-96 flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Due date
                </div>
                <DatePicker
                  selected={
                    proposedComplianceProgram.dueDate
                      ? moment(proposedComplianceProgram.dueDate).toDate()
                      : null
                  }
                  onChange={(date) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      dueDate: date ? moment(date).format("YYYY-MM-DD") : null,
                    })
                  }
                  dateFormat="MMM d, yyyy"
                  ariaLabelledBy="Compliance program due date"
                  className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="w-96 flex items-center gap-4">
                <div className="h-12 flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={
                      proposedComplianceProgram.recurrence !==
                      ComplianceProgramRecurrence.None
                    }
                    onChange={handleEnableRecurrence}
                  />{" "}
                  Repeat
                </div>
                {proposedComplianceProgram.recurrence !==
                  ComplianceProgramRecurrence.None && (
                  <Select
                    className="flex-1"
                    value={assertNonNull(proposedComplianceProgram.recurrence)}
                    options={[
                      {
                        value: ComplianceProgramRecurrence.Monthly,
                        label: "Monthly",
                      },
                      {
                        value: ComplianceProgramRecurrence.Quarterly,
                        label: "Quarterly",
                      },
                      {
                        value: ComplianceProgramRecurrence.Yearly,
                        label: "Yearly",
                      },
                    ]}
                    onChange={handleChangeRecurrence}
                    aria-label="Compliance program recurrence select"
                  />
                )}
              </div>
            </div>
          )}

          {proposedComplianceProgram.ongoing && (
            <div>
              <div className="w-96 flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Trigger
                </div>

                <Select
                  onChange={noop}
                  options={[{ value: 1, label: "Employee start date" }]}
                  value={1}
                />
              </div>
              <div className="mt-2 w-96 flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Starts (Days after start date)
                </div>
                <Input
                  type={"number"}
                  min={0}
                  step={1}
                  aria-label="Compliance program start day offset"
                  value={proposedComplianceProgram.startDateOffset ?? ""}
                  onChange={(e) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      startDateOffset: Math.trunc(Number(e.target.value)),
                    })
                  }
                />
              </div>
              <div className="w-96 flex flex-col gap-2">
                <div className="text-gray-500 text-xs uppercase font-semibold">
                  Due (Days after start date)
                </div>
                <Input
                  type={"number"}
                  min={0}
                  step={1}
                  aria-label="Compliance program due date offset"
                  value={proposedComplianceProgram.startDateDueDateOffset ?? ""}
                  onChange={(e) =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      startDateDueDateOffset: Math.trunc(
                        Number(e.target.value)
                      ),
                    })
                  }
                />
              </div>
            </div>
          )}

          <div className="border-t pt-2 flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Program period
            </div>

            {!proposedComplianceProgram.ongoing && (
              <div className="w-96">
                <Select<DateRangeType>
                  onChange={handleChangeProgramPeriod}
                  options={programPeriodOptions}
                  value={selectedProgramPeriodRange}
                />

                {selectedProgramPeriodRange === ExtraDateRangeEnum.custom && (
                  <>
                    <div className="text-gray-500 text-xs uppercase font-semibold">
                      Period start date
                    </div>
                    <DatePicker
                      selected={
                        proposedComplianceProgram.periodStartDate
                          ? moment(
                              proposedComplianceProgram.periodStartDate
                            ).toDate()
                          : null
                      }
                      onChange={(date) =>
                        setProposedComplianceProgram({
                          ...proposedComplianceProgram,
                          periodStartDate: date
                            ? moment(date).format("YYYY-MM-DD")
                            : null,
                          removeProgramPeriod: undefined,
                        })
                      }
                      dateFormat="MMM d, yyyy"
                      ariaLabelledBy="Compliance program period start date"
                      className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="text-gray-500 text-xs uppercase font-semibold">
                      Period end date
                    </div>
                    <DatePicker
                      selected={
                        proposedComplianceProgram.periodEndDate
                          ? moment(
                              proposedComplianceProgram.periodEndDate
                            ).toDate()
                          : null
                      }
                      onChange={(date) =>
                        setProposedComplianceProgram({
                          ...proposedComplianceProgram,
                          periodEndDate: date
                            ? moment(date).format("YYYY-MM-DD")
                            : null,
                          removeProgramPeriod: undefined,
                        })
                      }
                      dateFormat="MMM d, yyyy"
                      ariaLabelledBy="Compliance program period end date"
                      className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </>
                )}
              </div>
            )}

            {proposedComplianceProgram.ongoing && (
              <div className="w-96">
                <div className="w-96 flex flex-col gap-2">
                  <div className="text-gray-500 text-xs uppercase font-semibold">
                    Period start (Days after start date)
                  </div>
                  <Input
                    type={"number"}
                    min={0}
                    step={1}
                    aria-label="Compliance program due date offset"
                    value={
                      proposedComplianceProgram.startDatePeriodStartOffset ?? ""
                    }
                    onChange={(e) =>
                      setProposedComplianceProgram({
                        ...proposedComplianceProgram,
                        startDatePeriodStartOffset: Math.trunc(
                          Number(e.target.value)
                        ),
                      })
                    }
                  />
                </div>
                <div className="w-96 flex flex-col gap-2">
                  <div className="text-gray-500 text-xs uppercase font-semibold">
                    Period end (Days after start date)
                  </div>
                  <Input
                    type={"number"}
                    min={0}
                    step={1}
                    aria-label="Compliance program due date offset"
                    value={
                      proposedComplianceProgram.startDatePeriodEndOffset ?? ""
                    }
                    onChange={(e) =>
                      setProposedComplianceProgram({
                        ...proposedComplianceProgram,
                        startDatePeriodEndOffset: Math.trunc(
                          Number(e.target.value)
                        ),
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <div className="pt-4 mt-4 border-t grid grid-cols-6 gap-6">
            <div className="col-span-3 text-gray-500 text-xs uppercase font-semibold">
              {label("review", { pluralize: true, capitalize: true })}
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Providers
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Anonymity
            </div>
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Delivery Timing
            </div>
            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-32">Self and Manager</div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program performance assessment template"
                options={availableAssessmentGroupSelectOptions}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingTemplates =
                    proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentTemplates: existingTemplates.filter(
                      (g) => g.assessmentType !== AssessmentType.Performance
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentTemplate(
                  AssessmentType.Performance
                )}
                value={
                  selectedPerformanceAssessmentGroup &&
                  selectedPerformanceAssessmentGroup.questionSetIds.length > 0
                    ? availableAssessmentGroupSelectOptions.find(
                        ({ value }) =>
                          value ===
                          selectedPerformanceAssessmentGroup.questionSetIds[0]
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup ? "Default" : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup ? "Not anonymous" : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPerformanceAssessmentGroup ? (
                <Select<AssessmentGroupDelivery>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(
                    selectedPerformanceAssessmentGroup.delivery
                  )}
                  options={deliveryOptions}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(
                      AssessmentType.Performance
                    )({
                      delivery: opt.value,
                    })
                  }
                />
              ) : (
                "-"
              )}
            </div>

            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-32">
                {getAssessmentTypeLabel(AssessmentType.Manager)}
              </div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program manager assessment template"
                options={availableAssessmentGroupSelectOptions}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingTemplates =
                    proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentTemplates: existingTemplates.filter(
                      (g) => g.assessmentType !== AssessmentType.Manager
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentTemplate(
                  AssessmentType.Manager
                )}
                value={
                  selectedManagerAssessmentGroup &&
                  selectedManagerAssessmentGroup.questionSetIds.length > 0
                    ? availableAssessmentGroupSelectOptions.find(
                        ({ value }) =>
                          value ===
                          selectedManagerAssessmentGroup.questionSetIds[0]
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup ? "Default" : "-"}
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup ? (
                <Select<AssessmentGroupAnonymity>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(
                    selectedManagerAssessmentGroup.anonymity
                  )}
                  options={assessmentGroupAnonymityOptions(
                    AssessmentType.Manager
                  )}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(
                      AssessmentType.Manager
                    )({
                      anonymity: opt.value,
                    })
                  }
                />
              ) : (
                "-"
              )}
            </div>
            <div className="text-gray-500 self-center">
              {selectedManagerAssessmentGroup ? (
                <Select<AssessmentGroupDelivery>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(selectedManagerAssessmentGroup.delivery)}
                  options={deliveryOptions}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(
                      AssessmentType.Manager
                    )({
                      delivery: opt.value,
                    })
                  }
                />
              ) : (
                "-"
              )}
            </div>

            <div className="col-span-3 flex items-center gap-2">
              <div className="text-gray-500 text-xs w-32">
                {getAssessmentTypeLabel(AssessmentType.Peer)}
              </div>
              <ComboboxGeneric
                className="flex-1"
                aria-label="Compliance program peer assessment template"
                options={availablePeerAssessmentGroupSelectOptions}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() => {
                  const existingTemplates =
                    proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[];
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    assessmentTemplates: existingTemplates.filter(
                      (g) => g.assessmentType !== AssessmentType.Peer
                    ),
                  });
                }}
                onChangeValue={handleChangeAssessmentTemplate(
                  AssessmentType.Peer
                )}
                value={
                  selectedPeerAssessmentGroup &&
                  selectedPeerAssessmentGroup.questionSetIds.length > 0
                    ? availablePeerAssessmentGroupSelectOptions.find(
                        ({ value }) =>
                          value ===
                          selectedPeerAssessmentGroup.questionSetIds[0]
                      ) ?? null
                    : null
                }
              />
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup ? (
                <Select<AssessmentGroupProviders>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(selectedPeerAssessmentGroup.providers)}
                  options={assessmentGroupProviderOptions(AssessmentType.Peer)}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(AssessmentType.Peer)(
                      {
                        providers: opt.value,
                      }
                    )
                  }
                  aria-label="Assessment providers select"
                />
              ) : (
                "-"
              )}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup ? (
                <Select<AssessmentGroupAnonymity>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(selectedPeerAssessmentGroup.anonymity)}
                  options={assessmentGroupAnonymityOptions(AssessmentType.Peer)}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(AssessmentType.Peer)(
                      {
                        anonymity: opt.value,
                      }
                    )
                  }
                />
              ) : (
                "-"
              )}
            </div>
            <div className="text-gray-500 self-center">
              {selectedPeerAssessmentGroup ? (
                <Select<AssessmentGroupDelivery>
                  disabled={!canUpdateTemplates}
                  value={assertNonNull(selectedPeerAssessmentGroup.delivery)}
                  options={deliveryOptions}
                  onChange={(opt) =>
                    handleChangeAssessmentTemplateSettings(AssessmentType.Peer)(
                      {
                        delivery: opt.value,
                      }
                    )
                  }
                />
              ) : (
                "-"
              )}
            </div>
          </div>

          {(waffle.flag_is_active("goal-based-assessment") ||
            waffle.flag_is_active("career-competencies")) && (
            <div className="pt-4 mt-4 flex flex-col gap-2 border-t">
              {waffle.flag_is_active("goal-based-assessment") && (
                <div className="flex items-center gap-2">
                  <input
                    disabled={
                      !selectedPerformanceAssessmentGroup || !canUpdateTemplates
                    }
                    type="checkbox"
                    checked={
                      !!selectedPerformanceAssessmentGroup?.enableGoalAssessments
                    }
                    onChange={(evt) => {
                      const enabled = evt.target.checked;
                      handleChangeAssessmentTemplateSettings(
                        AssessmentType.Performance
                      )({
                        enableGoalAssessments: enabled,
                      });
                    }}
                  />{" "}
                  Include Goal-based{" "}
                  {label("review", { pluralize: true, capitalize: true })}
                </div>
              )}
              {waffle.flag_is_active("career-competencies") && (
                <IncludeRoleBasedForm
                  disabled={!canUpdateTemplates}
                  selectedPerformanceAssessmentGroup={
                    selectedPerformanceAssessmentGroup
                  }
                  onChangeAssessmentTemplateSettings={handleChangeAssessmentTemplateSettings(
                    AssessmentType.Performance
                  )}
                />
              )}
            </div>
          )}

          <div className="pt-4 mt-4 flex flex-col gap-2 border-t">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Meeting template
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={availableTopicTemplates}
                clearable
                disabled={!canUpdateTemplates}
                onClearValue={() =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    topicTemplates: [],
                  })
                }
                onChangeValue={(opt) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    topicTemplates: [{ id: opt.value }],
                  })
                }
                value={
                  selectedTopicTemplateId.length
                    ? availableTopicTemplates.find(
                        ({ value }) =>
                          value === assertNonNull(selectedTopicTemplateId[0]).id
                      ) ?? null
                    : null
                }
              />
            </div>
          </div>
          <div className="pt-4 mt-4 border-t flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Applies to
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={appliesToOptions}
                disabled={!canUpdateTemplates}
                onChangeValue={(opt) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    appliesTo: opt.value,
                  })
                }
                value={assertNonNull(
                  appliesToOptions.find(
                    ({ value }) => value === proposedComplianceProgram.appliesTo
                  )
                )}
              />
            </div>
          </div>
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Departments && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Departments
              </div>
              <div className="max-w-sm">
                <TeamPicker
                  canChange={canUpdateTemplates}
                  teams={appliesToTeams}
                  onAddTeam={(team) => {
                    setAppliesToTeams(appliesToTeams.concat(team));
                  }}
                  onRemoveTeam={(team) => {
                    setAppliesToTeams(
                      appliesToTeams.filter((t) => t.id !== team.id)
                    );
                  }}
                />
              </div>
            </div>
          )}
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Managers && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Managers
              </div>
              <div className="max-w-sm">
                <RecipientForm
                  canChange={canUpdateTemplates}
                  showCurrentUser
                  recipients={appliesToManagers}
                  onAddRecipient={(user) =>
                    setAppliesToManagers([...appliesToManagers, user])
                  }
                  onRemoveRecipient={(user) =>
                    setAppliesToManagers(
                      appliesToManagers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              </div>
            </div>
          )}
          {proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Users && (
            <div className="flex flex-col gap-2">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Users
              </div>
              <div className="max-w-sm">
                <RecipientForm
                  canChange={canUpdateTemplates}
                  showCurrentUser
                  recipients={appliesToUsers}
                  onAddRecipient={(user) =>
                    setAppliesToUsers([...appliesToUsers, user])
                  }
                  onRemoveRecipient={(user) =>
                    setAppliesToUsers(
                      appliesToUsers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Excluded users
            </div>
            <div className="max-w-sm">
              <RecipientForm
                canChange={canUpdateTemplates}
                showCurrentUser
                recipients={excludedUsers}
                onAddRecipient={(user) =>
                  setExcludedUsers([...excludedUsers, user])
                }
                onRemoveRecipient={(user) =>
                  setExcludedUsers(
                    excludedUsers.filter((u) => u.id !== user.id)
                  )
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Exclude employees with start date after
            </div>
            <div className="flex items-center gap-2">
              <DatePicker
                wrapperClassName="w-96"
                disabled={!canUpdateTemplates}
                selected={
                  proposedComplianceProgram.excludedHireDateAfter
                    ? moment(
                        proposedComplianceProgram.excludedHireDateAfter
                      ).toDate()
                    : null
                }
                onChange={(date) =>
                  setProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    excludedHireDateAfter: date
                      ? moment(date).format("YYYY-MM-DD")
                      : null,
                    removeExcludedHireDateAfter: undefined,
                  })
                }
                dateFormat="MMM d, yyyy"
                ariaLabelledBy="Compliance program exclude hire date after"
                className={classNames(
                  "px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500",
                  !canUpdateTemplates && "bg-gray-100"
                )}
              />
              {proposedComplianceProgram.excludedHireDateAfter && (
                <Button
                  className="text-sm"
                  onClick={() =>
                    setProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      excludedHireDateAfter: undefined,
                      removeExcludedHireDateAfter: true,
                    })
                  }
                  theme={buttonTheme.text}
                  disabled={isSavingComplianceProgram}
                >
                  Clear
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ComplianceProgramEdit;
